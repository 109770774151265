// Tooltips
"use strict";

import Tooltip from "../lib/tooltips";

const init = () => {
  document.querySelectorAll("[data-tooltip][title]").forEach(el => new Tooltip(el, {
    // min space between tooltip and edge of screen
    gutter: 10,
    // prepend: "",
    // Append a period if there isn't one for better screen reader experience
    append: el.title.trim().slice(-1) !== "." ? `<span class="u-screenreader">.</span>` : ""
  }));
};

// Init on initial page load
init();
