//------------------------------------------------------------------------
// Tooltips
//
// Based on https://inclusive-components.design/tooltips-toggletips/
//------------------------------------------------------------------------
"use strict";
import debounce from "lodash/debounce";

/**
 * Wrap the last X words in an HTML tag to prevent them from wrapping (i.e. orphans)
 * @param {HTMLElement} el - Tooltip DOM node
 * @param {Object} opts - Options
 * @param {string} [opts.classes=""] - Class(es) to apply to tooltip
 * @param {number} [opts.gutter=0] - Gutter space around main content well
 */
export default class Tooltip {
  constructor(el, opts) {
    this.el = el;
    this.tooltipContent = this.el.getAttribute("title");

    if (!this.tooltipContent) {
      console.warn(
        `“${this.el.textContent.trim()}” tooltip has no “title” content`
      );
      return false;
    }

    // Use Object.assign() to merge “opts” object with default values in this.options
    this.options = Object.assign(
      {},
      {
        classes: "", // string, accepts multiple space-separated classes
        gutter: 0, // gutter space around main content well
        // Prepend a colon for better screenreader output
        prepend: '<span class="u-screenreader">: </span>', // HTML to prepend to tooltip
        append: "", // HTML to append to tooltip
        wrapText: true // wrapper inner text in a span
      },
      opts
    );

    // Markdown regex for converting “title” content to HTML (supports bold, italic, and links)
    // TODO: Split out into separate module
    this.markdownRegex = [
      {
        regex: /\*\*(.+?)\*\*/g,
        replace: "<b>$1</b>"
      },
      {
        regex: /\*(.+?)\*/g,
        replace: "<i>$1</i>"
        // NOTE: Use this more complex regex if bold test isn’t executed first
        // regex: /([^\*])\*([^\*]+?[^\*])\*([^\*])/g,
        // replace: "$1<i>$2</i>$3"
      },
      {
        regex: /\[(.+?)\]\((.+?)\)/g,
        replace: '<a href="$2">$1</a>'
      }
    ];

    // Build tooltip, add attributes
    this.setup();

    // Event listeners
    this.events();

    // Determine tooltip position on load
    window.requestAnimationFrame(this.updatePosition.bind(this));
  }

  expandMarkdown(string) {
    this.markdownRegex.forEach(test => {
      string = string.replace(test.regex, test.replace);
    });

    return string;
  }

  setup() {
    this.isOpen = false;
    this.tooltipContent = this.expandMarkdown(this.tooltipContent);

    // Generate a unique ID for each tooltip so we can use “aria-describedby” on the toggle
    // https://gist.github.com/gordonbrander/2230317
    this.uniqueID = Math.random().toString(36).substr(2, 4);
    this.parentEl = this.el.parentNode;

    // Convert <span> tag to a link and add aria attributes
    // (button text can only wrap like an inline-block element)
    // Note: Don’t copy the “title” attribute since we don’t need it
    //       anymore and it could be announced by a screen reader.
    let linkEl = document.createElement("a");
    linkEl.href = "#"; // enables us to style the hover/focus/active states
    linkEl.className = this.el.className;
    linkEl.setAttribute("data-tooltip", "");
    linkEl.setAttribute("aria-describedby", "tooltip-" + this.uniqueID);
    linkEl.setAttribute("aria-expanded", "false");
    linkEl.setAttribute("role", "button");
    linkEl.innerHTML = this.el.innerHTML;
    this.el.parentNode.replaceChild(linkEl, this.el);
    this.el = linkEl;

    // Build tooltip
    this.tooltipEl = document.createElement("span");
    this.tooltipEl.setAttribute("aria-hidden", "true");
    this.tooltipEl.setAttribute("data-tooltip-menu", ""); // for styling purposes
    this.tooltipEl.setAttribute("id", `tooltip-${this.uniqueID}`);
    this.tooltipEl.setAttribute("role", "tooltip");

    if (this.options.classes) {
      this.tooltipEl.setAttribute("class", this.options.classes);
    }

    this.tooltipEl.innerHTML =
      this.options.prepend + this.tooltipContent + this.options.append;
    // We also could have built the markup using DOMParser() and template literals but I think it’s harder to read:
    // https://davidwalsh.name/convert-html-stings-dom-nodes
    // this.tooltipEl = new DOMParser().parseFromString(`
    //   <span id="tooltip-${this.uniqueID}" class="${this.options.classes}" aria-hidden="true" aria-expanded="false" role="alert"
    //     ${this.tooltipContent}
    //   </span>
    // `, 'text/html').body.firstChild;

    // Add tooltip menu element
    this.el.appendChild(this.tooltipEl);
  }

  events() {
    // Update tooltip position after web fonts have loaded
    document.documentElement.addEventListener("fonts-loaded", () => {
      window.requestAnimationFrame(this.updatePosition.bind(this));
    });

    // Toggle on click
    this.el.addEventListener("click", evt => {
      // Prevent default on clicks inside of tooltip menu
      if (this.tooltipEl.isSameNode(evt.target)) {
        evt.preventDefault();
      } else {
        this.toggle(evt);
      }
    });

    // Close tooltip if click off of it
    window.addEventListener("click", evt => {
      if (this.isOpen && !this.el.contains(evt.target)) {
        this.hideTooltip();
      }
    });

    // Close tooltip with escape key
    window.addEventListener("keydown", evt => {
      if (this.isOpen && evt.which === 27) {
        this.hideTooltip();
      }
    });

    // Hide on resize, recalc position
    window.addEventListener(
      "resize",
      debounce(event => {
        if (this.isOpen) {
          this.hideTooltip();
        }
        window.requestAnimationFrame(this.updatePosition.bind(this));
      }, 150)
    );
  }

  updatePosition() {
    // Reset alignment classes/attributes, centers tooltip over toggle
    this.el.classList.remove("is-fullwidth");
    this.tooltipEl.setAttribute("data-align", "");
    // Disable CSS transitions so we don’t have to wait for them to complete before measuring the tooltip
    this.tooltipEl.style.transition = "none";
    // Use setTimeout to force browser to recalc styles first
    window.setTimeout(this.calculatePosition.bind(this), 0);
  }

  calculatePosition() {
    let bodyWidth = window.innerWidth - this.options.gutter * 2;
    let bodyRightCutoff = window.innerWidth - this.options.gutter;

    // Toggle dimensions
    let toggleBoundingRect = this.el.getBoundingClientRect();
    let toggleLeftOffset = toggleBoundingRect.right;
    let toggleRightOffset = toggleBoundingRect.right;

    // Tooltip dimensions
    let tooltipBoundingRect = this.tooltipEl.getBoundingClientRect();
    let tooltipLeftOffset = tooltipBoundingRect.left;
    let tooltipRightOffset = tooltipBoundingRect.right;
    let tooltipWidth = tooltipBoundingRect.width;

    let cutoffRight = tooltipRightOffset > bodyRightCutoff;
    let cutoffLeft = tooltipLeftOffset < this.options.gutter;

    // Re-enable transitions
    this.tooltipEl.style.removeProperty("transition");

    // If tooltip fits, do nothing to keep it centered
    if (!cutoffLeft && !cutoffRight) {
      return false;
    }

    // If right side is cutoff…
    if (cutoffRight) {
      // …check if left side would fit before right aligning to toggle
      if (tooltipWidth + this.options.gutter <= toggleRightOffset) {
        this.tooltipEl.setAttribute("data-align", "right");
        return false;
      }
    }

    // If left side is cutoff…
    if (cutoffLeft) {
      // …check if right side would fit before left aligning to toggle
      if (
        toggleLeftOffset + tooltipWidth <=
        window.innerWidth - this.options.gutter
      ) {
        this.tooltipEl.setAttribute("data-align", "left");
        return false;
      }
    }

    // Tooltip can’t be aligned to toggle so make it fullwidth
    this.el.classList.add("is-fullwidth");
    this.tooltipEl.setAttribute("data-align", "full");
  }

  showTooltip(evt) {
    this.isOpen = true;
    this.el.setAttribute("aria-expanded", "true");
    this.tooltipEl.setAttribute("aria-hidden", "false");
  }

  hideTooltip(evt) {
    this.isOpen = false;
    this.el.setAttribute("aria-expanded", "false");
    this.tooltipEl.setAttribute("aria-hidden", "true");
  }

  // Toggle expandable
  toggle(evt) {
    evt.preventDefault();

    if (this.isOpen) {
      this.hideTooltip();
    } else {
      this.showTooltip();
    }
  }
}
