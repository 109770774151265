//------------------------------------------------------------------------
// Main nav
//
// Note: Requires support for window.matchMedia (IE 10+)
//------------------------------------------------------------------------
"use strict";

import ExpandToggle from "@threespot/expand-toggle";
import debounce from "lodash/debounce";

class MainNav {
  constructor() {
    // NOTE: Breakpoint must match $layout-nav-bp in _layout-vars.scss
    this.mediaQueryList = window.matchMedia("(min-width: 1100px)");
    this.navToggles = document.querySelectorAll(".Nav [data-expands]");
    this.navExpandToggles = []; // placeholder to store ExpandToggle instances
    this.navDropdownMenus = document.querySelectorAll(".Nav-sublist");
    this.cutoffClass = "is-cutoff"; // for dropdown menus that are cutoff on the right

    // Init search toggle
    this.initSearchToggle();

    // Init nav drop-down menus
    this.initDropdownMenus();

    // Setup global event listeners
    this.globalEvents();
  }

  initDropdownMenus() {
    // Init dropdown menus (primary and secondary navs)
    this.navToggles.forEach((el) => {
      let expandToggle = new ExpandToggle(el);

      // Push to placeholder array so we can control them later
      // e.g. in closeOtherNavToggles() and closeAllNavToggles()
      this.navExpandToggles.push(expandToggle);

      // Close other dropdown menus when opening a new one
      expandToggle.on("expand", () => {
        this.closeOtherNavToggles(expandToggle);
      });
    });

    // Check if dropdowns are cutoff on the right side.
    window.requestAnimationFrame(this.checkDropdownWidths.bind(this));

    // Re-check if dropdowns are cutoff on resize
    window.addEventListener(
      "resize",
      debounce(() => {
        window.requestAnimationFrame(this.checkDropdownWidths.bind(this));
      }, 150)
    );
  }

  initSearchToggle() {
    this.searchToggle = document.querySelector(".Header-searchToggle");
    this.searchField = document.querySelector(".SearchNav-input");

    // Init search toggle
    this.searchExpandToggle = new ExpandToggle(this.searchToggle);

    // Close nav menus when search toggle is expanded
    this.searchExpandToggle.on("expand", () => {
      this.closeAllNavToggles();
      // Focus search input
      this.searchField.focus();
    });
  }

  globalEvents() {
    // Close menus when switching from mobile to desktop layout
    this.mediaQueryList.addListener((evt) => {
      this.closeAllNavToggles();
      this.searchExpandToggle.collapse();
    });

    // Listen for breakpoint change to determine if mobile
    // nav toggle should be initialized or destroyed.
    this.mediaQueryList.addListener((evt) => {
      if (evt.matches) {
        this.destroyMobileNav();
      } else {
        this.initMobileNav();
      }
    });

    // Check if mobile nav toggle should be initialized on load
    if (!this.mediaQueryList.matches) {
      this.initMobileNav();
    }

    // Close expanded elements when clicking off of them
    window.addEventListener("click", (evt) => {
      if (
        !evt.target.closest(".Nav-toggle") &&
        !evt.target.closest(".Nav-sublist") &&
        !evt.target.closest(".Header-navToggle") &&
        !evt.target.closest("#menu") &&
        !evt.target.closest(".Header-searchToggle") &&
        !evt.target.closest("#search-nav")
      ) {
        this.closeAllNavToggles();
        this.searchExpandToggle.collapse();

        // Close mobile menu if initialized
        if (this.mobileNavToggle) {
          this.mobileNavToggle.collapse();
        }
      }
    });

    window.addEventListener("keydown", (evt) => {
      // Close menus with escape key
      if (evt.which === 27) {
        // Collapse search menu
        if (this.searchToggle.getAttribute("aria-expanded") == "true") {
          this.searchExpandToggle.collapse(evt);
          this.searchToggle.focus();
        } else {
          // Collapse nav menus
          this.closeAllNavToggles();
        }
      }

      // Navigate menus using left/right arrow keys
      if (evt.which === 37 || evt.which === 39) {
        let activeMenu = document.querySelector(
          '.Nav-toggle[aria-expanded="true"]'
        );

        if (!activeMenu) {
          return false;
        }

        // Index of the currently expanded menu
        let activeMenuIndex = this.navExpandToggles
          .map((item) => item.el)
          .indexOf(activeMenu);

        // Left arrow
        if (evt.which === 37 && activeMenuIndex > 0) {
          this.navExpandToggles[activeMenuIndex].collapse();
          this.navExpandToggles[activeMenuIndex - 1].expand();
          this.navExpandToggles[activeMenuIndex - 1].el.focus();
        } else if (
          evt.which === 39 &&
          activeMenuIndex < this.navExpandToggles.length - 1
        ) {
          // Right arrow
          this.navExpandToggles[activeMenuIndex].collapse();
          this.navExpandToggles[activeMenuIndex + 1].expand();
          this.navExpandToggles[activeMenuIndex + 1].el.focus();
        }
      }
    });
  }

  initMobileNav() {
    this.mobileNavToggle = new ExpandToggle(
      document.querySelector(".Header-navToggle")
    );
  }

  destroyMobileNav() {
    this.mobileNavToggle.destroy();
    this.mobileNavToggle = null;
  }

  // Check if any dropdown menus are cut off on the right side.
  // If so, add a class to right align them.
  checkDropdownWidths() {
    for (var i = 0, len = this.navDropdownMenus.length; i < len; i++) {
      var el = this.navDropdownMenus[i];

      // Remove the cutoff class first before measuring
      el.classList.remove(this.cutoffClass);

      var bounds = el.getBoundingClientRect();

      if (bounds.width + bounds.left > window.innerWidth) {
        el.classList.add(this.cutoffClass);
      } else {
        el.classList.remove(this.cutoffClass);
      }
    }
  }

  // Close all nav dropdwn menus
  closeAllNavToggles() {
    this.navExpandToggles.forEach((toggle) => {
      toggle.collapse();
    });
  }

  // Close all nav dropdwn menus except the current one
  closeOtherNavToggles(currentToggle) {
    this.navExpandToggles.forEach((toggle) => {
      if (currentToggle !== toggle) {
        toggle.collapse();
      }
    });
  }
}

// Init
new MainNav();
