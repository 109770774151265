"use strict";

import debounce from "lodash/debounce";

class Carousel {
  constructor(el) {
    var self = this;
    this.el = el;
    this.carouselList = this.el.querySelector(".Carousel-list");
    this.slides = this.el.querySelectorAll(".Carousel-item");
    this.captions = this.el.querySelectorAll(".Carousel-caption");
    this.counter = this.el.querySelector(".Carousel-counter");
    this.buttons = this.el.querySelector(".Carousel-buttons")
    this.prevButton = this.el.querySelector(".Carousel-button--prev");
    this.nextButton = this.el.querySelector(".Carousel-button--next");

    this.currentSlideIndex = 0;

    this.carouselList.classList.add("js-init");

    // to prevent content below slideshow from shifting, set caption height equal to tallest caption
    this.updateContainerHeight();

    this.updateButtonState();

    this.updateCounter();

    //display the first slide
    this.slides[0].classList.add('is-active');

    //listen for clicks on prev slide button
    this.prevButton.addEventListener("click", function(evt){
      self.prevSlide();
    });

    //listen for clicks on next slide button
    this.nextButton.addEventListener("click", function(evt){
      self.advanceSlide();
    })

    //listen for keypress to use left/right arrows to navigate
    window.addEventListener("keydown", function(evt) {
      self.keyboardNav(evt);
    })

    // Update maxCaptionHeight on window resize
    window.addEventListener(
      "resize",
      debounce(function(event) {
        // console.log('fired debounce');
        self.updateContainerHeight();
      }, 50)
    );
  }

  // find the longest caption so the height of the captions can be as tall as the longest
  updateContainerHeight() {
    var maxHeight = 0;
    // find the tallest caption and set that value as the maxCaptionHeight
    for (var i = 0; i < this.captions.length; i++) {
      var caption = this.captions[i];
      var captionHeight = caption.getBoundingClientRect().height;

      if (captionHeight > maxHeight) {
        maxHeight = captionHeight;
      }
    }

    //add padding to the bottom of the list equivalent to the tallest caption
    this.carouselList.style.paddingBottom = (maxHeight + 36) + "px";//magic number
  }

  // disable or enable buttons at start and end of list
  updateButtonState() {
    // Disable previous button if at first slide
    if (this.currentSlideIndex === 0) {
      this.prevButton.disabled = true;
      this.nextButton.disabled = false;
    }

    else if (this.currentSlideIndex === this.slides.length - 1) {
      this.prevButton.disabled = false;
      this.nextButton.disabled = true;
    }

    else {
      this.prevButton.disabled = false;
      this.nextButton.disabled = false;
    }
  }

  updateCounter() {
    this.counter.innerHTML = `${this.currentSlideIndex + 1} / ${this.slides.length}`;
  }

  // de-increment slides minus 1
  prevSlide() {
    this.currentSlideIndex -= 1;
    this.slides[this.currentSlideIndex + 1].classList.remove('is-active');
    this.slides[this.currentSlideIndex].classList.add('is-active');
    this.updateButtonState();
    this.updateCounter();
  }

  // increment slide plus 1
  advanceSlide() {
    this.currentSlideIndex += 1;
    this.slides[this.currentSlideIndex - 1].classList.remove('is-active');
    this.slides[this.currentSlideIndex].classList.add('is-active');
    this.updateButtonState();
    this.updateCounter();
  }

  // make carousel navigable with keyboard left/right arrows
  keyboardNav(evt) {
    if (evt.keyCode == 37 && this.currentSlideIndex > 0) {
      // left arrow
      this.prevSlide(this.currentSlideIndex);
    }
    else if (evt.keyCode == 39 && this.currentSlideIndex < (this.slides.length - 1)) {
       // right arrow
       this.advanceSlide(this.currentSlideIndex);
    }
  }

}

// Init
const nodes = document.querySelectorAll(".Carousel");
for (var i = 0, len = nodes.length; i < len; i++) {
  new Carousel(nodes[i]);
}

export default Carousel;
