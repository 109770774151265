//------------------------------------------------------------------------
// Jump link navigation (used on disease pages and news landing)
//------------------------------------------------------------------------
"use strict";

import ExpandToggle from "@threespot/expand-toggle";

class JumpLinkNav {
  constructor(el) {
    // NOTE: Breakpoint must match $layout-sidebar-bp
    this.mediaQueryList = window.matchMedia("(max-width: 859px)");// subtract 1px for max-width media queries
    this.el = el;
    this.contentEl = document.getElementById(this.el.getAttribute("data-expands"));

    // Listen for breakpoint change
    this.mediaQueryList.addListener(evt => {
      if (evt.matches) {
        this.init();
      } else {
        this.destroy();
      }
    });

    // Init on load
    if (this.mediaQueryList.matches) {
      this.init();
    }
  }

  init() {
    this.toggleExpand = new ExpandToggle(this.el);
  }

  destroy() {
    this.toggleExpand.destroy();
  }
}

// Init
document.querySelectorAll('.JumpLinkNav-toggle[data-expands]').forEach(el => new JumpLinkNav(el));
